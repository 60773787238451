import { React, useEffect, useState, useRef } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import { CrosswordProvider, DirectionClues, CrosswordGrid, CrosswordImperative } from '@jaredreisinger/react-crossword';
import {
  ModalHeader,
  ModalDescription,
  ModalContent,
  ModalActions,
  Button,
  Modal,
} from 'semantic-ui-react'

export default function App() {
  const [puzzle, setPuzzle] = useState(null);
  const [finished, setFinished] = useState(false);
  const [secret, setSecret] = useState('1234');
  const [progress, setProgress] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('/api/puzzle/get', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPuzzle(data);
        setLoading(false);
      });
  }, []);

  const handleCellChange = async (row, col, value) => {
    const newProgress = { ...progress, [`${row}-${col}`]: value };
    setProgress(newProgress);
  }

  const handleSubmit = () => { 
    const url = document.location.pathname;
    const uname = url.substring(url.lastIndexOf("/") + 1);
    fetch('/api/puzzle/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ uname, progress }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.verdict === 'correct') {
          setFinished(true);
          setSecret(data.flag);
        } else {
          alert('Incorrect');
        }
      }
    )
    
  }
  
  const closeModal = () => {
    setFinished(false);
  }
  return (
    <>
      <center>
        <h1 style={{fontSize: '45px'}}>The NYT Cursed-Word</h1>
        <hr style={{ height: '3px', backgroundColor: 'black'}}></hr>
        <br></br>
        
        <Modal
      onClose={closeModal}
      open={finished}
    >
      <div style={{ position: 'relative' }}>
    <button 
      onClick={closeModal}
      style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        background: 'none',
        border: 'none',
        fontSize: '20px',
        cursor: 'pointer'
      }}
    >
      ×
    </button>
  </div>
      <ModalHeader>Congratulations!!!</ModalHeader>
      <ModalContent image>
        <ModalDescription>
          Great Job! Your secret code is {secret}
        </ModalDescription>
      </ModalContent>
      <ModalActions>
        <a><Button color='green' onClick={closeModal}>
          Continue!
        </Button></a>
      </ModalActions>
    </Modal>
   { !loading ?    <CrosswordProvider useStorage={false} data={puzzle} onCellChange={(row, col, value) => handleCellChange(row, col, value)}>
          <div style={{ display: 'flex', gap: '2em', marginLeft: 'auto', width:'85%' }}>
            <div style={{ width: '50%' }}>
              <CrosswordGrid />
            </div>
            <div style={{ textAlign: 'left', fontSize: '17px'}}>
              <div>
                <Popup trigger=
                    {<button className='ui green button'> Rules </button>} 
                    contentStyle={{ width: "35%", borderRadius: '8px', alignItems: 'center' }}
                    modal nested>
                    {
                        close => (
                            <div className='modal'>
                                <center><h2> Rules </h2></center>
                                <div className='content'>
                                    <ol>
                                      <li> The answer to each individual box in the crossword is a digit (0-9).</li>
                                      <li> Decimal points are not allowed. In case you do encounter a decimal, skip the point. For example, 
                                           4.21 would be 421, and 0.892 would be 0892 (and not 892)!
                                      </li>
                                      <li> Once you get everything right, you will see a popup saying that you have finished the puzzle!</li>
                                    </ol>
                                </div>
                                <div>
                                    <button className='ui green button' onClick=
                                        {() => close()}>
                                            Continue!
                                    </button>
                                </div>
                            </div>
                        )
                    }
                </Popup>
                <button className='ui green button' onClick={handleSubmit}> Submit </button>
              </div>
              <br></br>
              <DirectionClues direction="across" />
              <br></br>
              <DirectionClues direction="down" />
            </div>
          </div>
        </CrosswordProvider> : 
        <div style={{fontSize: '20px', textAlign: 'center'}}>
          Loading...
        </div>}
      </center>
    </>
  )
}
